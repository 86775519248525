import React from 'react'

import {
  formatPhoneNumber,
  getSessionId,
  isWebpSupported,
  updateLocalStorage,
  setCategoryData,
} from 'helpers'

export const SiteContext = React.createContext()

export const SiteContextProvider = props => {
  const [ state, setState ] = React.useState({
    backgroundImages: [],
    isLoadingSiteData: true,
    city: '',
    region: '',
    phone: '',
  })

  const metadataSrc = window.__path ? `/${window.__path}/metadata.json` : '/metadata.json'
  let categoryData = {}

  updateLocalStorage()

  React.useEffect( () => {
    const updateContext = metadata => {
      // Select the hero.
      const index = Math.floor(Math.random() * metadata.backgroundImages.length)

      // Finally, update the context object.
      setState( prevState => ({
        ...prevState,
        ...metadata,
        currentBackgroundImage: isWebpSupported() && metadata.backgroundImages[index].webp ? metadata.backgroundImages[index].webp : metadata.backgroundImages[index].default,
        rialto: {
          ...metadata.rialto,
          publisher: new URLSearchParams(window.location.search).get('publisher') || metadata.rialto?.publisher,
        },
        isLoadingSiteData: false,
      }))
    }

    const updateVisitData = () => {
      // Set up a looper that will fire the follow up call to the PTM API once we've got all of the 3rd party ids.
      let tries = 0
      const maxTries = 10
      const interval = setInterval( () => {
        tries+= 1
        if(window.analytics && window.ga || tries > maxTries){
          fetch('https://twilioproxy.elocal.com/api/front_end/update_visit_data', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              anonymous_id: window.analytics?.user ? analytics.user().anonymousId() : null,
              ga_client_id: window.ga ? window.ga.getAll()[0].get('clientId') : null,
              session_id: getSessionId(),
            })
          })
          .catch( err => {} )
          clearInterval(interval)
        }
      }, 100)
    }

    const fetchIVRPhoneNumber = (categoryData, metadata) => {
      // Get the phone number.
      fetch('https://twilioproxy.elocal.com/api/front_end/ivr_search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          partner_campaign_ids: metadata.partner_campaign_ids,
          visit_data: {
            href: window.location.href,
            ...categoryData,
            session_id: getSessionId(),
          }
        })
      })
      .then( response => response.json() )
      .then( response => {
        // Check to see if any of the partner_campaign_ids from metadata are in the response object (as a key).  If so, update the phone number in metadata.
        metadata.partner_campaign_ids.map( id => {
          if(response[id]){
            metadata.phone = formatPhoneNumber(response[id])
          }
        })

        updateContext(metadata)
        updateVisitData()
      })
      .catch (err => {
        updateContext(metadata)
        updateVisitData()
      })
    }

    const fetchCategoryData = (metadata) => {
      // Get the category tracking data.
      fetch(`https://www.elocal.com/api/marketing/category/${metadata.categoryId}`)
        .then( response => response.json() )
        .then( categoryData => {
          setCategoryData(categoryData)
          fetchIVRPhoneNumber(categoryData, metadata)
        })
        .catch( err => {
          fetchIVRPhoneNumber({}, metadata)
        })
    }

    ;(async () => {
      fetch(metadataSrc)
      .then(response => response.json())
      .then(metadata => {
        // Update metadata from qs.
        const partner_campign_id_override = new URLSearchParams(window.location.search).get('partner_campaign_id')
        metadata.partner_campaign_ids = partner_campign_id_override ? [ partner_campign_id_override.toString() ] : metadata.partner_campaign_ids

        fetchCategoryData(metadata)
      })
      .catch( err => {
        console.log('metadata fetch failed')
        // _Could_ redirect to a 500 page...
      })
    })()
  }, [])

  return (
    <SiteContext.Provider value={[ state, setState ]}>
      {props.children}
    </SiteContext.Provider>
  )
}
