import React from 'react'

import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'

import { isValidState } from 'helpers'

const AboutUs = React.lazy(() => import('routes/AboutUs'))
const Landing = React.lazy(() => import('routes/Landing'))
const Privacy = React.lazy(() => import('routes/Privacy'))
const PrivacyCalifornia = React.lazy(() => import('routes/PrivacyCalifornia'))
const Profile = React.lazy(() => import('routes/Profile'))
const Results = React.lazy(() => import('routes/Results'))
const Terms = React.lazy(() => import('routes/Terms'))

const App = () => {
  return (
    <React.Suspense fallback={<span></span>}>
      <Switch>
        <Route path="*/about" render={props => <AboutUs {...props} />}/>
        <Route path="*/privacy" render={props => <Privacy {...props} />}/>
        <Route path="*/privacy-california" render={props => <PrivacyCalifornia {...props} />}/>
        <Route path="*/results" render={props => <Results {...props} />}/>
        <Route path="*/profile/:id" render={props => {
            props.match.params.slug = props.match.params.id.indexOf('.html') > -1 ? props.match.params.id : null
            props.match.params.id = props.match.params.id.indexOf('.html') > -1 ? null : props.match.params.id
            return <Profile {...props} slug={props.match.params.slug} />}
        }/>
        <Route path="*/p/gctc/:slug" render={props => <Profile {...props} slug={true} />}/>
        <Route path="*/terms" render={props => <Terms {...props} />}/>
        <Route exact path="*/:st/:city" render={props => {
          return isValidState(props.match.params.st) ?
            <Results {...props} state={props.match.params.st} city={props.match.params.city} /> :
            <Landing { ...props} />}
          }/>
        <Route path="/" render={props => <Landing { ...props} />}/>

        <Redirect to={{ ...location, pathname: '/' }} />
      </Switch>
    </React.Suspense>
  )
}

export default App
